import { Link } from 'react-router-dom';
import React from "react";
import LanguageSwitcher from "./LanguageSwitcher";

import "../../styles/common/Navbar.css";

const Navbar = () => {
  // const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // const toggleMobileMenu = () => {
  //   setIsMobileMenuOpen(!isMobileMenuOpen);
  // };

  return (
    <nav className="navbar">
      <div className="nav-container">
        {/* Logo Section */}
        <Link to="/" style={{ textDecoration: 'none' }}>
          <div className="nav-logo">
            <img src="/assets/common/Logo_white_dot.png" alt="Logo" className="logo-img" />
            <span className="logo-text">TIPSEI</span>
          </div>
        </Link>
        <LanguageSwitcher />
        {/* Hamburger Menu Icon */}
        {/* <div className="nav-toggle" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes className="menu-icon" /> : <FaBars className="menu-icon" />}
        </div> */}

        {/* Navigation Links */}
        {/* <ul className={`nav-menu ${isMobileMenuOpen ? 'active' : ''}`}>
          <li className="nav-item"><Link to="/" className="nav-link" onClick={() => setIsMobileMenuOpen(false)}>Lorem Ipsum</Link></li>
          <li className="nav-item"><Link to="/about" className="nav-link" onClick={() => setIsMobileMenuOpen(false)}>Lorem Ipsum</Link></li>
          <li className="nav-item"><Link to="/services" className="nav-link" onClick={() => setIsMobileMenuOpen(false)}>Lorem Ipsum</Link></li>
        </ul> */}

        {/* User Icon */}
        {/* <div className="nav-user">
          <FaUserCircle className="user-icon" />
        </div> */}
      </div>
    </nav>
  );
};

export default Navbar;
