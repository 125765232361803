import { useSearchParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../../styles/common/LanguageSwitcher.css'; 

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('');

  const changeLangAttribute = (lang) => {
    const htmlElement = document.querySelector('html');
    htmlElement.setAttribute('lang', lang);
  }

  const lang = searchParams.get('lang');

  useEffect(() => {
    // Set the initial selected language based on the current language
    const currentLanguage = lang ?? i18n.language;
    switch (currentLanguage) {
      case 'pl':
        setSelectedLanguage('PL');
        changeLangAttribute('pl');
        break;
      case 'en':
        setSelectedLanguage('EN');
        changeLangAttribute('en');
        break;
      case 'uk':
        setSelectedLanguage('UA');
        changeLangAttribute('uk');
        break;
      default:
        setSelectedLanguage('EN');
        changeLangAttribute('en');
    }
  }, [i18n.language, lang]);

  const changeLanguage = (lng, label) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(label);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="dropdown">
      <div className="dropdown-header" onClick={toggleDropdown}>
        {selectedLanguage}
      </div>
      {isOpen && (
        <ul className="dropdown-menu">
          <li onClick={() => changeLanguage('pl', 'PL')}>PL</li>
          <li onClick={() => changeLanguage('en', 'EN')}>EN</li>
          <li onClick={() => changeLanguage('uk', 'UA')}>UA</li>
        </ul>
      )}
    </div>
  );
};

export default LanguageSwitcher;