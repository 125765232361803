import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const Unsubscribe = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);
  
  const { t, i18n } = useTranslation();

  const id = searchParams.get('id');
  const lang = searchParams.get('lang');
  const newsletterUrl = process.env.REACT_APP_NEWSLETTER_API_URL;

  const isValidGuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(id);

  useEffect(() => {
    i18n.changeLanguage(lang);

    const unsubscribe = async () => {
      if (!isValidGuid) {
        setLoading(false);
        return;
      }

      try {
        const getResponse = await axios.get(`${newsletterUrl}/newsletter/${id}`);
        console.log(getResponse.data);
        if (getResponse.data !== undefined) {
          await axios.delete(`${newsletterUrl}/newsletter/${id}`);
          setIsUnsubscribed(true);
          setResponse(getResponse.data);
        }
      } catch (err) {
      } finally {
        setLoading(false);
      }
    };

    unsubscribe();
  }, [id, newsletterUrl, i18n, lang, isValidGuid]);

  if (!isValidGuid) return <p>{t('common.incorrect_link')}</p>;
  if (isUnsubscribed) return <p>{t('unsubscribe.success', { email: response.email })}</p>;

  if(!loading)return <p>{t('unsubscribe.not_found')}</p>;
};

export default Unsubscribe;