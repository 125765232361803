import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

i18n
  .use(HttpApi) // This loads translations from files
  .use(initReactI18next) // Passes i18n instance to react-i18next.
  .init({
    supportedLngs: ['en', 'pl', 'uk'], // Supported languages, e.g., English and Polish
    fallbackLng: 'pl', // Fallback language if the desired language is not available
    lng: 'pl', // Initial language
    backend: {
      loadPath: '/locales/{{lng}}/translation.json', // Path to your translation files
    },
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;