import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import "../styles/home/Home.css";

const Home = () => {

    const { t, i18n } = useTranslation(); 
    const [email, setEmail] = useState('');
    const [email_placeholder, setEmailPlaceholder] = useState(t('email_placeholder'));
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [localization, setLocalization] = useState(i18n.language);
    const newsletterUrl = process.env.REACT_APP_NEWSLETTER_API_URL;

    useEffect(() => {
        // Set the initial selected language based on the current language
        const currentLanguage = i18n.language;
        switch (currentLanguage) {
          case 'pl':
            setLocalization('pl');
            break;
          case 'en':
            setLocalization('en');
            break;
          case 'uk':
            setLocalization('uk');
            break;
          default:
            setLocalization('Language');
        }
      }, [i18n.language]);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setIsEmailValid(true);
    }

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try{
            if(!validateEmail(email)){
                setIsEmailValid(false);
            }
            else{
                setIsEmailValid(true);
                setEmailPlaceholder(t('email_thank_you_placeholder'));
                setEmail('');
                await axios.post(`${newsletterUrl}/newsletter`, {email,localization});
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="home-container">
            {/* Content Section */}
            <div className="content-section">
                <div className="header-section">
                    <h1><span className="highlight">TIPSEI</span> {t('description')}</h1>
                </div>

                {/* Explanation Section */}
                <div className="explanation-wrapper">
                    <h2 className="question-title">{t('what_is_going_on')}</h2>
                    <img src="../assets/home/Logo_full_orange.png" className="orange-logo" alt="Orange Tipsei logo" />
                    <p className="description-text">
                        {t('what_is_going_on_description')}
                    </p>
                </div>

                {/* User Columns (For Kelner, Klient, Właściciela) */}
                <div className="user-columns">
                    <div className="column">
                        <img src="../assets/home/icons/waiter.png" className="user-icon" alt="Waiter Icon"/>
                        <h3>{t('for_waiter')}:</h3>
                        <div className='column-details'>
                            <ul>
                                <li>{t('for_waiter_detail_1')}</li>
                                <li>{t('for_waiter_detail_2')}</li>
                                <li>{t('for_waiter_detail_3')}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="column">
                        <Icon icon="fluent:person-star-32-filled" className="user-icon" />
                        <h3>{t('for_client')}:</h3>
                        <div className='column-details'>
                            <ul>
                                <li>{t('for_client_detail_1')}</li>
                                <li>{t('for_client_detail_2')}</li>
                                {/* <li>{t('for_client_detail_3')}</li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="column">
                        <Icon icon="mingcute:house-2-line" className="user-icon" />
                        <h3>{t('for_owner')}:</h3>
                        <div className='column-details'>
                            <ul>
                                <li>{t('for_owner_detail_1')}</li>
                                <li>{t('for_owner_detail_2')}</li>
                                <li>{t('for_owner_detail_3')}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="subscription-section">
                    <div className="subscription-content">
                        <div className="subscription-text">
                            <h3>{t('interested')}</h3>
                            <p>{t('share_email')}</p>
                        </div>
                        <div className='subscription-input'>
                            <input 
                            type="email" 
                            placeholder={email_placeholder}
                            className={`email-input ${!isEmailValid ? 'invalid' : ''}`} 
                            value={email}
                            onChange={handleEmailChange}
                            required/>
                        </div>
                        <button className="desktop cta-button-phone" onClick={handleSubmit}>{t('send_cta')}</button>
                    </div>
                </div>
                <div className="desktop questions-section">
                    <p>{t('question_text')}</p>
                    <p>{t('write_email_text')}</p>
                </div>
            </div>

            {/* Phone Section (Right Side, 1/3 of the Page) */}
            <div className="phone-section">
                <img src="../../assets/home/Logo_full_white.png" alt="Tipsei Logo" className="phone-logo" />
                <img src={`../../assets/home/phone_mock_${localization}.png`} alt="Phone Mockup" className="desktop phone-image" />
                <img src={`../../assets/home/m_phone_mock_${localization}.png`} alt="Phone Mockup" className="mobile phone-image" loading='lazy'/>
                <div className="mobile-subscription-section">
                    <h3>{t('interested')}</h3>
                    <p>{t('share_email')}</p>
                    <div className="input-container">
                        <input type="email" 
                            placeholder={email_placeholder}
                            className={`email-input ${!isEmailValid ? 'invalid' : ''}`}
                            value={email}
                            onChange={handleEmailChange}
                            required/>
                    </div>
                </div>
                <button className="mobile cta-button-phone" onClick={handleSubmit}>{t('send_cta')}</button>
                <div className="mobile questions-section">
                    <p>{t('question_text')}</p>
                    <p>{t('write_email_text')}</p>
                </div>
            </div>
        </div>
    );
};

export default Home;